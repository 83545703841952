document.addEventListener('turbolinks:load', () => {
  const pageLinks = document.querySelectorAll('span.page > a')

  pageLinks.forEach((link) => {
    const parent = link.parentElement

    link.setAttribute('class', parent.getAttribute('class'))

    parent.parentElement.replaceChild(link, parent)
  })
})
