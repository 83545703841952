document.addEventListener('turbolinks:load', () => {
  const scrapNode = document.getElementById('reporting-scrap-report')
  if (scrapNode) {
    import('../reporting/scrap')
      .then(initFunc => initFunc.default(scrapNode))
  }

  const downtimeNode = document.getElementById('reporting-downtime-report')
  if (downtimeNode) {
    import('../reporting/downtime')
      .then(initFunc => initFunc.default(downtimeNode))
  }

  const oeeTrendNode = document.getElementById('reporting-oee_trend-report')
  if (oeeTrendNode) {
    import('../reporting/oee_trend')
      .then(initFunc => initFunc.default(oeeTrendNode))
  }

  const topDowntimeNode = document.getElementById('reporting-top_downtime-report')
  if (topDowntimeNode) {
    import('../reporting/top_downtime')
      .then(initFunc => initFunc.default(topDowntimeNode))
  }
})
