document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.filter > input, .filter > select').forEach((filterInput) => {
    filterInput.addEventListener('change', () => {
      filterInput.closest('form').submit()
    })
  })

  document.querySelectorAll('.clear-single-filter').forEach((buttonElement) => {
    buttonElement.addEventListener('click', (e) => {
      e.preventDefault()

      const inputs = buttonElement.parentElement.querySelectorAll('input, select')
      if (inputs.length > 0) {
        inputs.forEach((input) => {
          input.value = ''
        })
        inputs[0].closest('form').submit()
      } else {
        const input = buttonElement.closest('th').querySelector('input, select')
        input.value = ''
        input.closest('form').submit()
      }
    })
  })
})
