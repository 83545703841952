let nextUpdate

function timeUntillNextSecond () {
  return 1000 - new Date() % 1000
}

function prettyClockInt (number) {
  return (parseInt(number)).toString().padStart(2, '0')
}

function timerValue (difference) {
  const seconds = difference / 1000
  return `${prettyClockInt(seconds / 3600)}:${prettyClockInt(seconds / 60 % 60)}:${prettyClockInt(seconds% 60)}`
}

const DurationTimer = {
  update: (nodes) => {
    nodes.forEach((element) => {
      element.innerHTML = timerValue(new Date() - new Date(element.dataset.startDate))
    })
    nextUpdate = setTimeout(() => DurationTimer.update(nodes), timeUntillNextSecond())
  },

  begin: () => {
    clearTimeout(nextUpdate)
    const nodes = document.querySelectorAll('[data-duration-timer]')
    DurationTimer.update(nodes)
  },

  stop: () => {
    clearTimeout(nextUpdate)
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.querySelectorAll('[data-duration-timer]').length !== 0) {
    DurationTimer.begin()
  } else {
    DurationTimer.stop()
  }
})
