document.addEventListener('turbolinks:load', () => {
  const node = document.getElementById('line-manager')
  if (node) {
    import('../line_manager/OperatorPanel/init')
      .then(initFunc => initFunc.default(node, node.dataset))
  }
})

document.addEventListener('turbolinks:load', () => {
  // TODO: do something with namings
  const node = document.getElementById('reworks-screens-line-manager')
  if (node) {
    import('../line_manager/ReworksPanel/init')
      .then(initFunc => initFunc.default(node, node.dataset))
  }
})
