export const FLASH_TYPE = {
  alert: 'alert',
  notice: 'notice',
}

export const FLASH_TIMEOUT = {
  [FLASH_TYPE.alert]: 1000 * 15,
  [FLASH_TYPE.notice]: 1000 * 5,
}

export const showFlash = (message, type = FLASH_TYPE.notice, id = undefined) => {
  if (flashAlreadyExists(id)) {
    return
  }

  const flashElement = createFlashElement(message, type, id)
  const timeout = FLASH_TIMEOUT[type] || FLASH_TIMEOUT[FLASH_TYPE.notice]
  const closeFlash = makeCloseFlash(flashElement)

  flashElement.querySelector('[data-close]').addEventListener('click', closeFlash)
  setTimeout(closeFlash, timeout)

  document.querySelector('.content-area').appendChild(flashElement)
}

export const closeAllExistingFlashes = () => {
  document.querySelectorAll('.flash').forEach((flashElement) => {
    const type = getFlashElementType(flashElement)
    const timeout = FLASH_TIMEOUT[type] || FLASH_TIMEOUT[FLASH_TYPE.notice]
    const closeFlash = makeCloseFlash(flashElement)

    flashElement.querySelector('[data-close]').addEventListener('click', closeFlash)
    setTimeout(closeFlash, timeout)
  })
}

const getFlashElementType = (flashElement) => {
  const type = Object.values(FLASH_TYPE).find((type) => flashElement.classList.contains(`flash--${type}`))
  return type || FLASH_TYPE.notice
}

const flashAlreadyExists = (id) => {
  return !id || !!document.querySelector(`.content-area .flash[data-flash-id="${id}"]`)
}

const createFlashElement = (message, type, id) => {
  const div = document.createElement('div')
  div.classList.add('flash')
  div.classList.add(`flash--${type}`)
  if (id) {
    div.dataset.flashId = id
  }

  const content = document.createElement('div')
  content.appendChild(document.createTextNode(message))
  div.appendChild(content)

  const close = document.createElement('div')
  close.dataset.close = true
  close.innerHTML = '&times;'
  div.appendChild(close)

  return div
}

const makeCloseFlash = (flashElement) => {
  let isClosing = false

  return () => {
    if (!flashElement || isClosing) {
      return
    }

    isClosing = true
    flashElement.style.animation = 'flashHide 1s cubic-bezier(0.23, 1, 0.32, 1)'
    flashElement.style.opacity = 0

    setTimeout(() => flashElement?.remove?.(), 1000)
  }
}
