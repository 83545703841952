import Cookies from 'js-cookie'

document.addEventListener('turbolinks:load', () => {
  const node = document.querySelector('[data-sidebar-collapse]')
  if (!node) {
    return
  }

  node.addEventListener('click', () => {
    Array.from(document.getElementsByClassName('app__sidebar')).forEach((el) => {
      const status = el.classList.toggle('app-sidebar--expanded')
      Cookies.set('sidebar-expanded', status ? 'true' : '')
    })
  })
})

document.addEventListener('turbolinks:before-render', function (event) {
  const sidebar = event.data.newBody.getElementsByClassName('app__sidebar')[0]
  if (!sidebar) {
    return
  }

  // Remove flickering when navigating after change of sidebar state and turbolinks cached previous version
  if (Cookies.get('sidebar-expanded') && !sidebar.classList.contains('app-sidebar--expanded')) {
    sidebar.classList.add('app-sidebar--expanded')
  } else if (!Cookies.get('sidebar-expanded') && sidebar.classList.contains('app-sidebar--expanded')) {
    sidebar.classList.remove('app-sidebar--expanded')
  }
})
