const selector = '[data-flatpickr]'
const selectorTime = '[data-flatpickrt]'
const selectorOnlyTime = '[data-flatpickr-only-time]'

document.addEventListener('turbolinks:load', () => {
  const flatpickrInputs = document.querySelectorAll(selector)
  const flatpickrTimeInputs = document.querySelectorAll(selectorTime)

  if (!flatpickrInputs.length && !flatpickrTimeInputs) {
    return
  }

  const imports = [
    import('flatpickr'),
    import('flatpickr/dist/l10n'),
    import('./flatpickr.css')
  ]

  Promise.all(imports).then(([module, localeModule]) => {
    const locale = localeModule.default[window.locale]
    locale.firstDayOfWeek = 1

    module.default(selector, { locale })
    module.default(selectorTime, { enableTime: true, enableSeconds: true, time_24hr: true, dateFormat: "Y-m-d H:i", locale })
    module.default(selectorOnlyTime, { enableTime: true, time_24hr: true, dateFormat: "H:i", noCalendar: true, locale })
  })
})
