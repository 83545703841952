document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.collapsable-div').forEach((collapsableDiv) => {
    collapsableDiv.addEventListener('click', () => {
      const full = collapsableDiv.parentElement.querySelector('.collapsable-div__full')
      if (!full) return

      full.style.display = 'block'
      collapsableDiv.parentElement.querySelector('.collapsable-div__short').style.display = 'none'
    })
  })
})
